import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import Card from '@/components/Card'
import CardSmall from '@/components/CardSmall'
import Button from '@/components/Button'
import ButtonA from '@/components/ButtonA'
import WhatDoIDo from '@/components/partials/WhatDoIDo'
import WhoAmI from '@/components/partials/WhoAmI'
import Partnership from '@/components/Partnership'
import Header from '@/components/partials/Header'
import TypedComponent from '@/components/TypedComponent'
import Link from 'next/link'
import FlagsmithComponent from '@/components/Flagsmith'


const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  return (
    <>
      <Head>
        <title>Carles Javierre | DevOps</title>
        <meta name="description" content="DevOps/SRE, Observability & Cybersecurity for all businesses" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" sizes="any"/>

        <meta property="og:title" content="Carles Javierre - DevOps/SRE"/>
        <meta property="og:type" content="website"/>
        <meta property="og:description" content="DevOps/SRE, Observability & Cybersecurity for all businesses"/>
        <meta property="og:url" content="https://carlesjavierre.com"/>
        <meta property="og:image" content="/me/cj.webp"/>
        <meta property="og:site_name" content="Carles Javierre"/>

        <meta property="twitter:title" content="Carles Javierre - DevOps"/>
        <meta property="twitter:description" content="DevOps/SRE, Observability & Cybersecurity for all businesses"/>
        <meta property="twitter:image" content="/me/cj.webp"/>
      </Head>
      <main>

        <Header currentTab='none'/>

        <div>
              <div className="container mx-auto p-4 py-8 ">
                  {/* <div className="py-12 ">
                      <div className="flex justify-center h-40 py-16">
                        <TypedComponent></TypedComponent>
                      </div>
                  </div> */}

                  <div className="py-16 px-8 text-justify md:text-left md:px-10 lg:px-40 border-b border-gray-300 font-sans justify-center" id="about">
                    <div className="flex justify-center">
                        <h4 className="text-xl font-mono mb-6 mx-auto">Who am I?</h4>
                    </div>

                    <div className="flex justify-center h-40 py-16">
                        <TypedComponent></TypedComponent>
                    </div>
                    <WhoAmI/>
                  </div>

                  <div className="py-16 md:px-16 lg:px-40 border-b border-gray-300 font-mono" id="services">
                      <WhatDoIDo/>
                  </div>

                  <div className="py-16 border-b border-gray-300 font-mono" id="technologies">
                      <div className="flex justify-center">
                          <h3 className="text-bold text-2xl py-6">My core tech stack</h3>
                      </div>
                      <div className="flex flex-wrap justify-center py-8 ">
                          <Card title='Kubernetes' text='Immutable and documented (GitOps) environments. Scalability, security and resiliency. Being cloud agnostic is really easy.' image='/external/Kubernetes.svg' alt='Kubernetes' />
                          <Card title='Datadog' text="Observability and monitoring redefined. Get deep insights into your application's guts, improve performance, security  and reduce cloud costs." image='/partners/Datadog.svg' alt='Datadog' />
                          <Card title='AWS' text='The cloud provider by excellence. Near-infinite scalability with the highest customization levels on the market.' image='/external/AWS.png' alt='Amazon Web Services' />
                          <Card title='Cloudflare' text='DDoS protection, WAF, Bot Management and Workers are a MUST once you know they exist. ZeroTrust, DNS Filtering & VPN replacement for endpoint security. ' image='/partners/Cloudflare.png' alt='Cloudflare' />
                          <Card title='Gitlab' text='Your own Gitlab instance for maximum control and security. Fully available for air-gapped environments. CI/CD templating  ' image='/external/Gitlab.png' alt='Gitlab' />
                      </div>
                      <div className="flex justify-center">
                          <h5 className="text-bold text-2xl py-6">And so much more</h5>
                      </div>
                      <div className="flex justify-center text-center">
                          <h5 className="text-bold text-lg py-6">I actually use way more technologies... These are my top picks too!</h5>
                      </div>
                      <div className="flex flex-wrap justify-center py-8 ">
                          <CardSmall title='SentinelOne' text='New generation EDR protection' image='/partners/SentinelOne.png' alt='SentinelOne' />
                          <CardSmall title='NinjaOne' text='The best RMM for fleet management and Compliance' image='/partners/NinjaOne.svg' alt='NinjaOne' />
                          <CardSmall title='Docker' text='Get the exact same environment everywhere.' image='/external/Docker.png' alt='Docker' />
                          <CardSmall title='Acronis' text='Reliable cloud managed backups' image='/external/Acronis.png' alt='Acronis' />
                      </div>
                      <div className="flex justify-center">
                          <p className="text-xs py-6 text-gray-500">*All brand logos used are IP of their respective companies</p>
                      </div>
                  </div>

                  <div className="py-16 border-b" id="contact">
                      <div className="flex justify-center">
                          <div className="mx-auto text-primary-dark hover:text-primary transition-all duration-150 ease-in-out">
                              <i className="far fa-3x fa-laugh"></i>
                          </div>
                      </div>
                      <div className="flex justify-center text-center">
                          <h5 className="text-bold text-2xl py-6 font-mono">Do you think I&#39;m a good fit?</h5>
                      </div>
                      <div className="flex justify-center text-center">
                          <h5 className="text-lg pb-16 font-mono">I encourage you to send me a message and let&#39;s see how we can work together</h5>
                      </div>
                      <div className="flex justify-center font-mono py-8">
                          <ButtonA text='Contact me' href='https://www.malt.es/profile/carlesjavierre' title='Malt' />
                      </div>
                      <div className="flex justify-center text-center font-mono py-8 lg:px-40">
                        <h5 className="text-lg font-mono">You can also drop me an email at info@ the domain you&#39;re visiting or send me a message on <Link className="text-primary" href="https://www.linkedin.com/in/carlesjavierre/">LinkedIn</Link></h5>
                      </div>
                  </div>

                  <FlagsmithComponent />

                  <div className=" py-16">
                      <div className="flex justify-center pb-8">
                          <h5 className="sm:text-3xl text-2xl antialiased font-mono text-primary-dark">Official partnerships</h5>
                      </div>
                      <div className="flex flex-wrap justify-center">
                          <Partnership image='/partners/Datadog.svg' alt='DataDog Partner' />
                          <Partnership image='/partners/Cloudflare.png' alt='Cloudflare Partner' />
                          <Partnership image='/partners/SentinelOne.png' alt='SentinelOne Partner' />
                          <Partnership image='/partners/NinjaOne.svg' alt='NinjaOne Partner' />
                      </div>
                  </div>

              </div>
          </div>

      </main>
    </>
  )
}
