import Link from "next/link";
import ButtonA from "@/components/ButtonA";
import TypedComponent from "@/components/TypedComponent";


const WhoAmI = () => {
  return (
    <>
        <div className="flex flex-col sm:flex-row justify-between items-center">
            <div>
            <img src="/me/cj.webp" alt="avatar" className="w-32 h-32 rounded-full mb-8 lg:mb-0" />
            </div>
            <div className="flex-1 text-sm font-mono sm:text-xl ml-0 md:ml-8">
                <h2 className="my-4">I&#39;m Carles Javierre, a young enterpreneur from Barcelona who loves technology. I started programming when I was 12 years old. Since then, my curiosity has lead me to dive in many different technologies. I&#39;ve been into very diverse technical projects that required unique solutions, providing extensive knowledge over the years.</h2>
                <h2 className="my-4">My goal is to be a tech swiss-knife, but not any swiss knife. I only work with the best tools, products and the quality of my work thrives for excellence beyond all</h2>
                <h2 className="my-4">As of today, I focus on DevOps/SRE, Observability and Cybersecurity. But you could find me exploring any tech branch that might seem interesting.</h2>
            </div>
        </div>
        <div className="text-center my-16"><ButtonA text='See my stack' href="#technologies" title='Tech stack'/></div>

        <h1 className="text-center text-xl font-mono font-semibold mt-16 mb-4">Recently named <a className="text-primary underline" target="_blank" href="https://datadoghq.com/ambassadors/"> Datadog Ambassador</a></h1>
    </>
  );
};

export default WhoAmI;