const WhatDoIDo = () => {
  return (
    <>
        <div className="flex justify-center pb-8 px-4 md:px-0 lg:px-8 ">
            <h4 className="md:text-2xl text-xl">What do I do?</h4>
        </div>
        <div className="flex justify-start px-8 md:px-0 lg:px-20">
            <ul className="px-8 list-disc">
                <li className="mb-4 md:mb-1"><h2>Curated Datadog implementations: get your Observability to the next level</h2></li>
                <li className="mb-4 md:mb-1"><h2>Maximizing Cloudflare&#39;s security offerings</h2></li>
                <li className="mb-4 md:mb-1"><h2>Designing custom and scalable cloud architectures using Kubernetes</h2></li>
                <li className="mb-4 md:mb-1"><h2>Cybersecurity: from your cloud infrastructure to employee endpoints</h2></li>
                <li className="mb-4 md:mb-1"><h2>Migration and transformation of legacy applications to the Cloud</h2></li>
                <li className="mb-4 md:mb-1"><h2>General IT consulting</h2></li>
            </ul>
        </div>
        <div className="flex justify-center py-8">
            <div className="mx-auto text-primary-dark hover:text-primary transition-all duration-150 ease-in-out">
                <i className="fas fa-5x fa-cloud"></i>
            </div>
        </div>

        <div className="flex justify-center text-center py-8 px-4 md:px-0 lg:px-8">
            <h3 className="md:text-2xl text-xl">That&#39;s great, but ... what does it bring to my company?</h3>
        </div>
        <div className="flex justify-start px-8 md:px-0 lg:px-20">
            <ul className="px-8 list-disc">
                <li className="mb-4 md:mb-1"><h2>Get to know every issue that happens within your infrastructure/application and the reasons behind the bottlenecks. Proactive monitoring for the most demanding teams.</h2></li>
                <li className="mb-4 md:mb-1"><h2>Having a WAF is great, but it requires effort to tune it right. Security misconfigurations happen, and great knowledge of the tools is mandatory</h2></li>
                <li className="mb-4 md:mb-1"><h2>Getting ready for the future with the biggest open-source project for cloud-agnostic implementations</h2></li>
                <li className="mb-4 md:mb-1"><h2>You need to know what happened and what caused it. Having a security incident requires knowledge of what data could be accessed and if it was exfiltrated.</h2></li>
                <li className="mb-4 md:mb-1"><h2>Getting up to date with technology is mandatory: from security issues to future-proofing your company.</h2></li>
            </ul>
        </div>
    </>


  );
};

export default WhatDoIDo;